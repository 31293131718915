import { H3Event } from 'h3';
import { useNavStore } from '../store/nav';
import { useGiftsStore, GiftsState } from '../store/gifts';
import { useFeatureStore } from '../store/feature';
import { useRootStore } from '@/store/index';
import { useGeodataStore } from '~/store/geodata';
import { getRegionEnvSettings } from '~/util/regional-config';
import { useDyStore } from '~/store/dy';
type FullLocale = {
  nuxtI18n: {
    code: string;
    iso: string;
    name: string;
  };
  vueI18n: {
    currency: {
      style: string;
      currencySymbol: string;
      currency: string;
    };
  };
};

type NLocale = FullLocale['nuxtI18n'];

function findBasicLocaleByCode(localeCode: string, locales: NLocale[]) {
  const basicLocale = locales.find((locale) => locale.code === localeCode);
  return basicLocale;
}

export async function useServerInit() {
  // TODO - useI18n() is throwing errors when calling server init in a function
  const i18n = useI18n();
  const { locale, localeProperties, locales, setLocale, getBrowserLocale, getLocaleCookie, setLocaleCookie } = i18n;
  const featureStore = useFeatureStore();
  const rootStore = useRootStore();
  const navStore = useNavStore();
  const giftsStore = useGiftsStore();
  const config = useRuntimeConfig();
  const geoStore = useGeodataStore();
  const dyStore = useDyStore();
  const { geoLocate } = geoStore;

  // const baseUrl = config?.public?.baseUrl || '';
  // const estimatedLocation = baseUrl + config?.url || '';
  // Old commented code was here - use git history to see it

  async function geoI18n(event: H3Event) {
    const localeCookie = getLocaleCookie();
    if (import.meta.server) {
      const noopCodes = ['xx', 'XX', ''];
      const browserLocale: string = getBrowserLocale() || ''; // uses headers server side
      const browserLang = browserLocale.split('-')[0];
      const browserCountry = browserLocale.split('-')[1];
      geoLocate(event);
      if (localeCookie) return;
      // if CF does not know the country, 'xx' is used as a value
      const countryCode = geoStore.countryCode || 'xx';
      let countryToUse: string = countryCode;

      if (noopCodes.includes(countryToUse)) countryToUse = browserCountry;

      let codeToUse = `${browserLang}-${countryToUse}`;
      codeToUse = codeToUse.toLowerCase();

      if (locale.value === codeToUse) return;

      const { code: existingLocale } = findBasicLocaleByCode(codeToUse, locales.value as NLocale[]) || {};
      const fallbackCode = `en-${countryCode.toLowerCase()}`;

      codeToUse = existingLocale || fallbackCode || browserLocale;

      // in this context, setLocale returns Promise<void>
      await setLocale(codeToUse);
    }

    // Running this on client so it sets the cookie the way i18n expects;
    // there was an issue running this server-side even though i18n
    // devved this to work server side
    if (import.meta.client) {
      if (!localeCookie && locale.value) {
        setLocaleCookie(locale.value);
      }
    }
  }

  const numberFormat = i18n.getNumberFormat(localeProperties.value.code)?.currency;
  const currencyCode = numberFormat?.currency || '';

  // duped assignment
  const regionLocaleProperties = useRegionLocaleProperties({
    ...localeProperties.value,
    ...(localeProperties.value && { currencyCode }),
  });

  onBeforeMount(() => {
    // duped assignment
    regionLocaleProperties.value = {
      ...localeProperties.value,
      ...(localeProperties.value && { currencyCode: numberFormat?.currency || '' }),
    };
  });

  const event = useRequestEvent();
  await geoI18n(event!);
  const $cfFetch = cfFetchBuilder(event);

  const { environment } = config.public;
  const publicRegionSettings = config?.public?.regionEnvs?.publicRegionSettings;
  const { data: asyncData } = await useAsyncData(async () => {
    const { regionCode } = regionLocaleProperties.value;
    const yotpoKey = getRegionEnvSettings('yotpoKey', regionCode, publicRegionSettings);
    rootStore.setYotpoSettings({ appKey: yotpoKey });
    const [featureData, headerData, footerData, brandSettingsData, algoliaSettingsData, gwpSettingsData, dySettings] =
      await Promise.allSettled([
        featureStore.getFeatureFlags({
          event,
          query: {
            locale: locale?.value,
          },
        }),
        navStore.getHeader({
          event,
          query: {
            locale: locale?.value,
          },
        }),
        navStore.getFooter({
          event,
          query: {
            locale: locale?.value,
          },
        }),
        $cfFetch(`/api/contentstack/brand-settings?locale=${locale?.value}`, {
          method: 'GET',
        })
          .then((res: any) => {
            return res?.data?.all_brand_settings?.items[0] || {};
          })
          .catch((err: any) => {
            wrapError('ServerInit Error:', environment, err);
            return {};
          }),
        $cfFetch(`/api/contentstack/algolia-settings?locale=${locale?.value}`, {
          method: 'GET',
        })
          .then((res: any) => {
            return res?.data?.all_algolia_settings?.items[0] || {};
          })
          .catch((err: any) => {
            wrapError('ServerInit Error:', environment, err);
            return {};
          }),
        giftsStore.getPromotion({ event, query: { locale: locale?.value, region: regionCode } }),
        dyStore.updateDyChoices(locale.value),
      ]);
    return {
      feature: featureData.status === 'fulfilled' ? featureData.value : {},
      header: headerData.status === 'fulfilled' ? headerData.value : {},
      footer: footerData.status === 'fulfilled' ? footerData.value : {},
      brandSettings: brandSettingsData.status === 'fulfilled' ? brandSettingsData.value : {},
      algoliaSettings: algoliaSettingsData.status === 'fulfilled' ? algoliaSettingsData.value : {},
      gwpSettings: gwpSettingsData.status === 'fulfilled' ? gwpSettingsData.value : {},
      dySettings: dySettings.status === 'fulfilled' ? dySettings.value : {},
    };
  });
  // wrapLog('useServerInit', [asyncData.value], 'CYAN');

  if (process.server) {
    featureStore.setFeatureFlags(asyncData.value?.feature);
    navStore.setHeader(asyncData.value?.header);
    navStore.setFooter(asyncData.value?.footer);
    rootStore.setBrandSettings(asyncData.value?.brandSettings);
    rootStore.setAlgoliaSettings(asyncData.value?.algoliaSettings);
    giftsStore.setInitialData(asyncData.value?.gwpSettings as GiftsState);
  } else {
    useYotpoRegion();
    // Trying to see if updates here are needed for SPA navigation or when locale changes
    useLmbCommonData(i18n);
    rootStore.setAlgoliaSettings(asyncData.value?.algoliaSettings);
    navStore.setHeader(asyncData.value?.header);
    navStore.setFooter(asyncData.value?.footer);
  }
}
