import { useProductDataStore } from '~/store/product-data';

export async function useLmbCommonData(i18n: any) {
  try {
    // TODO - check what is best behavior for this function/store usage
    // if (process.server) return;
    const commonDataStore = useProductDataStore();
    const { localeProperties } = i18n;
    const numberFormat = i18n.getNumberFormat(localeProperties.value.code)?.currency;
    const currencyCode = numberFormat?.currency || '';

    const { regionCode, storeHash } = localeProperties.value;

    const url = new URL('/api/bc/init', window.location.origin);

    url.searchParams.set('currencyCode', currencyCode);
    url.searchParams.set('regionCode', regionCode);

    let response = await fetch(url);

    if (response.status === 404) {
      url.pathname = '/api/bc/lmb-nxint';
      url.searchParams.set('storeHash', storeHash);
      response = await fetch(url);
    }

    const { variants, priceListRecords, priceListAssignments } = (await response.json()) as {
      variants: any[];
      priceListRecords: any[];
      priceListAssignments: any[];
    };

    commonDataStore.initialSetup({ variants, priceListRecords, priceListAssignments });
  } catch (error) {
    console.error('Error in common data setup'); // eslint-disable-line no-console
    console.error(error); // eslint-disable-line no-console
  }
}
